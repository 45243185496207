import { useLogin } from '@pankod/refine-core';
import { Button, Form, Input, Typography } from '@pankod/refine-antd';

import formField from './utils/formField';

import styles from './styles.module.scss';

type LoginVariables = {
  username: string;
  password: string;
};

const { username, password } = formField;

const LoginPage = () => {
  const { mutate: login, isLoading } = useLogin<LoginVariables>();

  const onSubmit = (values: LoginVariables) => {
    login(values);
  };

  return (
    <div className={styles.adminLoginPage}>
      <div>
        <Typography.Title>Login</Typography.Title>

        <Form
          onFinish={onSubmit}
          layout="vertical"
          className={styles.formLogin}
          disabled={isLoading}
        >
          <Form.Item
            name={username.name}
            label={username.label}
            rules={[{ required: username.required, message: username.errMsg }]}
          >
            <Input
              placeholder={username.placeholder}
              size="large"
              className="w-100"
            />
          </Form.Item>

          <Form.Item
            name={password.name}
            label={password.label}
            rules={[{ required: password.required, message: password.errMsg }]}
          >
            <Input.Password placeholder={password.placeholder} size="large" />
          </Form.Item>

          <div className="text-end">
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
            >
              Login
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default LoginPage;
